import { gql } from ':src/__generated__/gql';

export const viewerQuery = gql(/* GraphQL */ `
  query ViewerQuery {
    viewer {
      id
      scope
      email
      smallAvatar
      avatarPath
      mediumAvatar
      firstName
      lastName
      fullName
      timezone
      creditCards {
        id
        lastFourDigits
        expirationMonth
        expirationYear
        preferred
        cardType
      }
      userSubscription {
        active
        expiresAt
        cancelledAt
        subscriptionProduct {
          name
        }
        subscriptionPrice {
          amountInCents
        }
      }
      featureFlags
      learningTermsAcceptedAt
      canAccessMotivoLearning
    }
  }
`);
